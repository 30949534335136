



































































































import { Component, Vue } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'

import PageHeader from '@/components/PageHeader.vue'
import UserInitials from '@/components/UserInitials.vue'
import EditEmailModal from '@/components/settings/EditEmailModal.vue'
import EditPasswordModal from '@/components/settings/EditPasswordModal.vue'
import AddCaregiverModal from '@/components/settings/AddCaregiverModal.vue'
import AddLastFourSSNModal from '@/components/settings/AddLastFourSSNModal.vue'
import CaregiversCard from '@/components/settings/CaregiversCard.vue'
import SettingsNavCard from '@/components/settings/SettingsNavCard.vue'
import SkeletonCircle from '@/components/SkeletonCircle.vue'
import SkeletonLine from '@/components/SkeletonLine.vue'
import SkeletonListGroup from '@/components/SkeletonListGroup.vue'
import User from '@/models/User'
import HelpSearchCard from '@/components/HelpSearchCard.vue'
import Card from '@/components/Card.vue'

@Component({
  components: {
    Card,
    PageHeader,
    UserInitials,
    EditEmailModal,
    EditPasswordModal,
    AddCaregiverModal,
    AddLastFourSSNModal,
    CaregiversCard,
    SkeletonCircle,
    SkeletonLine,
    SkeletonListGroup,
    SettingsNavCard,
    HelpSearchCard,
  },
  computed: {
    ...mapState('user', ['me']),
    ...mapState('pwa', ['loadPWAPrompts']),
    ...mapGetters('asyncStatus', ['isInProgress', 'getError']),
  },
})
export default class SettingsView extends Vue {
  isInProgress!: (key: string) => boolean
  getError!: (key: string) => Error
  me?: User

  get loadingUser() {
    return this.isInProgress('user/loadMe')
  }

  get hasError() {
    return this.getError('user/loadMe')
  }

  get isAdmin() {
    return this.$pillpack.isAdminMasquerading
  }

  get canEditSSN() {
    return this.me?.ssn === '0000'
  }

  showPWAModal() {
    this.$store.commit('pwa/toggleModal', true)
  }
}
