
















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import { validationMixin } from 'vuelidate'
import { required, maxLength } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'
import { PPError } from '@/ppapi/PPError'
import Modal from '@/components/Modal.vue'
import AlertMessage from '@/components/AlertMessage.vue'

const MAX_SSN_LENGTH = 4

@Component({
  components: { Modal, AlertMessage },
  mixins: [validationMixin],
  directives: { mask },
  computed: {
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
  validations: {
    ssn: { required, maxLength: maxLength(MAX_SSN_LENGTH) },
  },
})
export default class AddLastFourSSNModal extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean

  @Prop({ type: String, required: true })
  modalId!: string

  ssn: string = ''

  get invalidFeedbackMessage(): TranslateResult | null {
    if (this.$v.ssn && this.$v.ssn.$dirty) {
      if (!this.$v.ssn.required) {
        return this.$t('_validations.required', { field: 'ssn' })
      }

      if (!this.$v.ssn.maxLength) {
        return this.$t('_validations.maxLength', { max: MAX_SSN_LENGTH })
      }
    }

    return null
  }

  close() {
    this.$bvModal.hide(this.modalId)
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.ssn = ''
    this.$store.commit('asyncStatus/reset', { key: 'user/updateLastFour' })
    this.$v.$reset()
  }

  async updateSetting(): Promise<void> {
    this.$v.$touch()

    if (this.$v.$invalid) {
      return
    }

    const ok = await this.$bvModal.msgBoxConfirm('You will not be able to set this again.', {
      id: 'update-ssn-confirm-modal',
      title: 'Are you sure you want to update this customer’s SSN?',
      centered: true,
      okTitle: 'Yes, update',
      cancelTitle: 'No, cancel',
      cancelVariant: 'cancel',
    })

    if (!ok) {
      this.close()
      return
    }

    await this.$store.dispatch('user/updateLastFour', {
      ssn: this.ssn,
    })

    if (this.hasSucceeded('user/updateLastFour')) {
      this.$bvToast.toast(this.$t('Last four digits have been updated') as string, {
        title: this.$t('SSN updated') as string,
        variant: 'success',
      })
      this.close()
    }
  }
}
