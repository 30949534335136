






























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import { PPError } from '@/ppapi/PPError'
import AlertMessage from '@/components/AlertMessage.vue'
import Modal from '@/components/Modal.vue'
import { PPHttpError } from '@/ppapi/PPHttp'

@Component({
  components: { AlertMessage, Modal },
  mixins: [validationMixin],
  computed: {
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
    ...mapGetters('user', ['caregiverExists']),
  },
  validations: {
    email: { required, email },
  },
})
export default class AddCaregiver extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean
  caregiverExists!: (email: string) => boolean

  readonly actionKey = 'user/addCaregiver'

  @Prop({ type: String, required: true })
  modalId!: string

  email: string = ''

  get isValid(): boolean | null {
    if (this.caregiverExists(this.email)) {
      return false
    } else if (this.$v.$dirty) {
      return !this.$v.$invalid
    }
    return null
  }

  get invalidFeedbackMessage(): TranslateResult | null {
    if (!this.$v.email.required) {
      return this.$t('_validations.required', { field: 'email' })
    } else if (!this.$v.email.email) {
      return this.$t('_validations.email')
    } else if (this.caregiverExists(this.email)) {
      return this.$t('caregiver.alreadyExists')
    }

    return null
  }

  emailUsedError(error: PPError | null) {
    return error && error instanceof PPHttpError && error.status === 422
  }

  close() {
    this.$bvModal.hide(this.modalId)
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.email = ''
    this.$store.commit('asyncStatus/reset', { key: this.actionKey })
    this.$v.$reset()
  }

  async updateSetting(): Promise<void> {
    this.$v.$touch()

    if (this.isValid) {
      const email = this.email
      await this.$store.dispatch(this.actionKey, email)

      if (this.hasSucceeded(this.actionKey)) {
        this.$bvToast.toast(this.$t('Invite sent to {email}', { email }) as string, {
          title: this.$t('Caregiver invited') as string,
          variant: 'success',
        })
        this.close()
      }
    }
  }
}
