import { serializable, primitive, alias } from 'serializr'

export default class PasswordUpdate {
  @serializable(alias('current_password', primitive()))
  currentPassword: string = ''

  @serializable(alias('password', primitive()))
  password: string = ''

  @serializable(alias('password_confirmation', primitive()))
  passwordConfirmation: string = ''
}
