



















































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import CareRelationship, { CaregiverStatus } from '@/models/CareRelationship'
import UserInitials from '@/components/UserInitials.vue'
import Card from '@/components/Card.vue'
import { PPError } from '@/ppapi/PPError'

@Component({
  components: {
    Card,
    UserInitials,
  },
  computed: {
    ...mapGetters('asyncStatus', ['getError']),
  },
})
export default class CaregiversCard extends Vue {
  getError!: (key: string) => PPError | null

  @Prop({ type: Array, required: true })
  caregivers!: CareRelationship[]

  async removeCaregiver(relationship: CareRelationship) {
    let confirmed = false
    if (relationship.status === CaregiverStatus.Invited) {
      confirmed = await this.$bvModal.msgBoxConfirm(
        this.$t('caregiver.cancelInviteMessage', {
          email: relationship.caregiverEmail,
        }) as string,
        {
          id: 'confirm-cancel-invite',
          okVariant: 'outline-danger',
          okTitle: this.$t('Cancel invite') as string,
          cancelTitle: this.$t('Nevermind') as string,
          centered: true,
        },
      )
    } else {
      const caregiver = relationship.caregiver
      confirmed = await this.$bvModal.msgBoxConfirm(
        this.$t('caregiver.removeCaregiverMessage', {
          name: caregiver && caregiver.name,
        }) as string,
        {
          id: 'confirm-remove-caregiver',
          okVariant: 'outline-danger',
          okTitle: this.$t('Remove caregiver') as string,
          cancelTitle: this.$t('Nevermind') as string,
          centered: true,
        },
      )
    }

    if (confirmed) {
      await this.$store.dispatch('user/removeCaregiver', relationship.id)

      if (this.getError('user/removeCaregiver')) {
        this.$bvToast.toast(this.$t('caregiver.removeCaregiverError') as string, {
          title: this.$t('Error') as string,
          variant: 'warning',
        })
      }
    }
  }
}
