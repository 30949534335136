






















































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { TranslateResult } from 'vue-i18n'
import { validationMixin, Validation } from 'vuelidate'
import { minLength, sameAs, required } from 'vuelidate/lib/validators'
import { PPError } from '@/ppapi/PPError'
import Modal from '@/components/Modal.vue'
import AlertMessage from '@/components/AlertMessage.vue'
import PasswordUpdate from '@/models/PasswordUpdate'

enum InputTypes {
  password = 'password',
  text = 'text',
}

// TODO all validation, error messages, and screen reader alerts
@Component({
  components: { Modal, AlertMessage },
  mixins: [validationMixin],
  computed: {
    ...mapGetters('asyncStatus', ['getError', 'isInProgress', 'hasSucceeded']),
  },
  validations: {
    passwordUpdate: {
      currentPassword: { required },
      password: { required, minLength: minLength(8) },
      passwordConfirmation: {
        required,
        sameAsPassword: sameAs('password'),
      },
    },
  },
})
export default class EditPassword extends Vue {
  hasSucceeded!: (key: string) => boolean
  getError!: (key: string) => PPError | null
  isInProgress!: (key: string) => boolean

  @Prop({ type: String, required: true })
  modalId!: string

  showPassword: boolean = false
  passwordUpdate: PasswordUpdate = new PasswordUpdate()

  get inputType() {
    return this.showPassword ? InputTypes.text : InputTypes.password
  }

  get invalidPasswordMessage(): TranslateResult | null {
    const validation = this.$v?.passwordUpdate?.password

    if (!validation) {
      return null
    }

    if (!validation.required) {
      return this.$t('_validations.required', { field: 'password' })
    } else if (!validation.minLength) {
      return this.$t('_validations.passwordLength')
    }

    return null
  }

  get invalidConfirmationMessage(): TranslateResult | null {
    const validation = this.$v?.passwordUpdate?.passwordConfirmation

    if (!validation) {
      return null
    }

    if (!validation.required) {
      return this.$t('_validations.required', { field: 'password confirmation' })
    } else if (!validation.sameAsPassword) {
      return this.$t('_validations.passwordMatch')
    }

    return null
  }

  get invalidCurrentMessage(): TranslateResult | null {
    const validation = this.$v?.passwordUpdate?.passwordConfirmation

    if (!validation) {
      return null
    }

    if (!validation.required) {
      return this.$t('_validations.required', { field: 'current password' })
    }

    return null
  }

  isValid(validation: Validation): Boolean | null {
    return validation.$dirty ? !validation.$error : null
  }

  toggleShowHide() {
    this.showPassword = !this.showPassword
  }

  close() {
    this.$bvModal.hide(this.modalId)
  }

  onHide() {
    this.reset()
  }

  reset() {
    this.passwordUpdate = new PasswordUpdate()
    this.$store.commit('asyncStatus/reset', { key: 'user/updatePassword' })
    this.$v.$reset()
  }

  async updateSetting(): Promise<void> {
    this.$v.$touch()

    if (!this.$v.$invalid) {
      await this.$store.dispatch('user/updatePassword', this.passwordUpdate)

      if (this.hasSucceeded('user/updatePassword')) {
        this.$bvToast.toast(this.$t('Your password was successfully changed') as string, {
          title: this.$t('Password updated') as string,
          variant: 'success',
        })
        this.close()
      }
    }
  }
}
